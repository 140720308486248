import React, { useEffect, useRef } from 'react';
import './App.css';
import cat from '../src/assets/cat.png'
import dog from '../src/assets/dog.png';
import fromicon from '../src/assets/form-icon.svg';
import { FaArrowRight, FaCheck } from 'react-icons/fa'; // Import icons at the top
import { FaHeartbeat } from 'react-icons/fa'; // Exemplo com React Icons (opcional)
import logo from '../src/assets/logo_multicare.svg';
import { FaPhoneAlt } from 'react-icons/fa'; 
import { BsSignStopFill } from "react-icons/bs";
import { IoIosInformationCircle } from "react-icons/io";
import { TiArrowUpOutline } from "react-icons/ti";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaIdCard } from 'react-icons/fa';  
import nameicon from '../src/assets/name.svg';
import phoneicon2 from '../src/assets/phone.svg';
import { IoPricetagsOutline } from "react-icons/io5";
import { useLocation } from 'react-router-dom';  // Certifique-se de importar useLocation

import zipicon from '../src/assets/zip.svg';
import nificon from '../src/assets/nif.svg';
import { MdEuroSymbol } from 'react-icons/md';  
import { FaUser } from 'react-icons/fa';  
import { FaEnvelope } from 'react-icons/fa';
import emailicon from '../src/assets/email.svg';
import arrowcta from '../src/assets/arrow-cta.svg';
import { useState } from 'react';
import Fidelidadepet from './Fidelidadepet';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Footer from './Footer';
import NotificationPopUp from './Notification';
import SecondVisitModal from './SecondVisitModal';
import BenefitsGrid from './BenefitsGrid';  
import Service from './Service';
import { lightGreen } from '@mui/material/colors';
import { FaCheckCircle, FaUserMd,  FaPercentage } from 'react-icons/fa';  
import PageNotFound from './PageNotFound'; // Componente 404


function App() {
  const [isSubmitting, setIsSubmitting] = useState(false); // Adicionar este estado
  const [countdown, setCountdown] = useState(15); 
  const targetUrl = 'https://www.fixo.pt/fixo/?utm_source=referral&utm_medium=afiliacao&utm_campaign=fid_pontual_fixo_referral_afiliacao_lp_pets_typ&utm_content=produto'; // URL de destino

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [DivShowNameEmail, setDivShowNameEmail] = useState(0);


const nomeinp = useRef();
const emailinp = useRef();
const nifinp = useRef();
const zipinp = useRef();
const mobileinp = useRef();
  const [formstep, setFromstep] = useState(1);
 
  let sessionValue = sessionStorage.getItem('user_session');
  let sessionValueGuid = sessionStorage.getItem('user_sessionGuid');
  const getUUID = () => {
    if (crypto && typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID();
    } else {
      // Simple UUID generator (not cryptographically secure)
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
  };
  // Verifica e define `user_session`
  if (!sessionValue) {
    sessionValue = `session_${new Date().getTime()}`;  
    sessionStorage.setItem('user_session', sessionValue);  
  }

 
  useEffect(() => {
    // Extrair parâmetros da URL
    const queryParams = new URLSearchParams(window.location.search);
  
    // Obter os valores das query strings e converter para minúsculas
    let nameFromEmail = queryParams.get("NameFromEmail")?.toLowerCase() || "";
    let emailFromEmail = queryParams.get("emailFromEmail")?.toLowerCase() || "";
  
   
    // Definir valores padrão se for "name" ou "email"
    nameFromEmail = nameFromEmail === "name" ? "" : nameFromEmail;
    emailFromEmail = emailFromEmail === "email" ? "" : emailFromEmail;
 
    // Se existir `NameFromEmail`, atualiza o campo de nome
    if (nameFromEmail && nomeinp && nomeinp.current) {
      setFormdata((prevState) => ({ ...prevState, name: nameFromEmail }));
      nomeinp.current.value = nameFromEmail;  // Define valor no input de nome
    }
    if (!checkemailload(emailFromEmail)) {
      console.log("E-mail inválido.");
      return;
    }
  
  

  
    // Se existir `emailFromEmail` e ele for válido, atualiza o campo de e-mail
    if (emailFromEmail && emailinp && emailinp.current) {
      setFormdata((prevState) => ({ ...prevState, email: emailFromEmail }));
      emailinp.current.value = emailFromEmail;  // Define valor no input de e-mail
    }
  
    // Se ambos os campos estão preenchidos e válidos, avança para o passo 2
    if (nameFromEmail && emailFromEmail && nomeinp && nomeinp.current) {
      setFromstep(2);  // Define o próximo passo apenas se ambos os valores existirem
      document.getElementById('Txt2Step').innerHTML =
        nomeinp.current.value.split(" ")[0] + ", já está no último passo para solicitar a sua simulação.";
        setDivShowNameEmail(1);
        sendDataToGoogleSheet({
          fromwhere: "step1" ,
          name: nomeinp.current.value,
          email: emailinp.current.value,
        });
    }
  }, []);  // Dependências vazias para executar o useEffect apenas uma vez ao carregar a página
  
 
  
const [formdata, setFormdata] = useState(
  {
    pettype:'',
    agerange:'',
    petage:'',
    name:'',
email:'',
nif:'',
zip:'',
phone:''
  }
);

const marks = [
  
];
function valuetext(value) {
   return `${value}`;
}
const handleSliderChange = (event, newValue) => {
  let oldData={...formdata}
 
  setFormdata(oldData);
};

 
const [showMoreText, setShowMoreText] = useState(false); // State to control visibility

 

const validateform = (e) =>{
  let oldData={...formdata}
  let inp = e.target.name;
  let inpvalue =  e.target.value.slice(0, e.target.maxLength);
  if (inp === "nif" && inpvalue.length === 9) {
    zipinp.current.focus();
  }
 

  oldData[inp] =inpvalue;
    setFormdata(oldData);
}



const validatezip = (e) => {
  let value = e.target.value.replace(/ /g, "");  
  let inputNumbersOnly = value.replace(/\D/g, "");
  if (inputNumbersOnly.length > 8) {
    inputNumbersOnly = inputNumbersOnly.substr(0, 8);
  }
  const splits = inputNumbersOnly.match(/.{1,4}/g);
   let spacedNumber = "";
  if (splits) {
    spacedNumber = splits.join("-");
  }
  let oldData={...formdata}
  let inp = e.target.name;
  oldData[inp] =spacedNumber;
  if (value.length == 8) {
    mobileinp.current.focus();
  }
    setFormdata(oldData);
};
useEffect(()=>{

 
});
useEffect(() => {
  window.addEventListener('load', handleLoad, { once: true });

  return () => window.removeEventListener('load', handleLoad);
}, []);

const handleLoad = () => {
  sendDataToGoogleSheet({ fromwhere: "load" });
};

const checkempty = (inp) =>{
  if(inp.current.value == '')
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checknumberonly = (inp) =>{
  if(!isNaN(inp.current.value))
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}



const checkemailload = (email) => {
  var emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  return emailregex.test(email);
};


const checkemail = (inp) =>{
  const errorMessage = document.getElementById('emaillbl'); 
  var emailregex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if(!emailregex.test(inp.current.value))
    {
      errorMessage.textContent = 'Introduza um endereço de e-mail válido.'; 

      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      errorMessage.textContent = ''; 
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checkmobile = (inp, length) => {
  const errorMessage = document.getElementById('mobilelbl');
  let value = inp.current.value;
  value = value.replace(/\D/g, ''); // Remove non-digit characters
  let iserror = false;

  // Check if the number is 9 digits long and starts with allowed prefixes
  if (value.length === 9 && value[0] === '9' && ['1', '2', '3', '6'].includes(value[1])) {
    iserror = false;
    errorMessage.textContent = '';
  } else if (value.length === 9 && (value[0] !== '9' || !['1', '2', '3', '6'].includes(value[1]))) {
    errorMessage.textContent = '91, 92, 93 ou 96.';
    iserror = true;
  } else if (value.length < 9) {
    errorMessage.textContent = '9 dígitos.';
    iserror = true;
  }

  if (iserror) {
    inp.current.style.backgroundColor = "white";
    inp.current.classList.add("shadowinput");
    inp.current.focus();
    return false;
  } else {
    inp.current.classList.remove("shadowinput");
    return true;
  }
};


const checkpostalcode = (inp, length) =>{
  const errorMessage = document.getElementById("ziplbl");
  let iserror = false;
  if(inp.current.value.length != length)
    {
      errorMessage.textContent = 'O código postal deve ser exactamente 7 dígitos.';
      iserror = true;
    }else{
      iserror = false;
      errorMessage.textContent = '';
    }

  if(iserror)
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}


const checknif = (inp, length) => {
  const errorMessage = document.getElementById("niflbl");
  let iserror = false;
  const nif = inp.current.value;

  // Verifica o comprimento do NIF
  if (nif.length != length) {
    errorMessage.textContent = 'O NIF deve ter exatamente 9 dígitos.';
    iserror = true;
  } else if (!validateNIF(nif)) {
    // Se o comprimento está correto, valida o NIF com as regras adicionais
    errorMessage.textContent = 'O NIF fornecido é inválido.';
    iserror = true;
  } else {
    iserror = false;
    errorMessage.textContent = '';
  }

  if (iserror) {
    inp.current.style.backgroundColor = "white";
    inp.current.classList.add("shadowinput");
    inp.current.focus();
    return false;
  } else {
    inp.current.classList.remove("shadowinput");
    return true;
  }
}

// Função auxiliar para validar o NIF (integrada dentro do checknif)
function validateNIF(nif) {
  // Verifica se o primeiro ou os dois primeiros dígitos são válidos
  if (
    !['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
    !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))
  ) {
    return false;
  }

  // Cálculo do total com base nos primeiros 8 dígitos
  let total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;

  // Calcula o módulo 11
  let modulo11 = total - parseInt(total / 11) * 11;
  let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

  // Verifica se o último dígito corresponde ao comparador
  return nif[8] == comparador;
}


const submitform = (step) => {

  if (!checkempty(nomeinp) ) {
    setFromstep(1)
    return;
  }
 
 
  if (!checkemail(emailinp) ) {
    setFromstep(1)
    return;
  }
 
  if (step == 1) {
    sendDataToGoogleSheet({
      fromwhere: "step1" ,
      name: nomeinp.current.value,
      email: emailinp.current.value,
    });
    setFromstep(2)
  }
  

  document.getElementById('Txt2Step').innerHTML = 
  nomeinp.current.value.split(" ")[0] + ", já está no último passo para solicitar a sua simulação.";


 
  
  if(formstep == 2){
if(
 checknif(nifinp, 9) == true
  && checkpostalcode(zipinp, 8) == true
  && checkmobile(mobileinp,9) == true
)
 {
  sendDataToGoogleSheet({
    fromwhere : "step3",
    name: nomeinp.current.value,
    email: emailinp.current.value,
    phone: mobileinp.current.value,
    vat: nifinp.current.value,
    postalCode: zipinp.current.value
  }); 

  senddata();

  const timer = setInterval(() => {
    setCountdown((prevCountdown) => {
      if (prevCountdown === 1) {
        window.location.href = targetUrl; // Redireciona após a contagem regressiva
        clearInterval(timer); // Para o timer após o redirecionamento
      }
      return prevCountdown - 1;
    });
  }, 1000);
  
     

}

  }
}




const senddata = async  () => {

  if (isSubmitting) return; // Bloqueia se já estiver enviando dados


  let sessionValueGuid = sessionStorage.getItem('user_sessionGuid');
  
  // Se a variável for nula ou indefinida, gere um novo GUID
  if (!sessionValueGuid) {
    sessionValueGuid = getUUID(); // Gera um novo GUID
    sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Salva no sessionStorage
  }

  // Se o nome for "Affiliate", dispara a lógica específica
  if (formdata.name.trim().toLowerCase() === "affiliate" || formdata.name.trim().toLowerCase().includes("test")) {
    setFromstep(3);
    const img = document.createElement("img");
    img.src = `https://emas.uinterbox.com/tracking/cnv?org=5634&evt=11103&cid=${sessionValueGuid }`;
    img.height = "1";
    img.width = "1";
    img.style.display = "none"; // Opcional: esconde a imagem
    document.body.appendChild(img);
    return;
  }


  const data = {
  nif: formdata.nif,
  name: formdata.name,
  email: formdata.email,
  phone: formdata.phone,
  zip: formdata.zip,
  argan: "",
  petname:"",
  licenseplate: "",
  observations: "",
  source: 469,
  scheduletype: 0,
  isClient: true,
  family: 0,
  uuid: sessionValueGuid,
  birthdate: "",
  date: new Date().toISOString()
  }
  const response = await fetch(
    //'https://localhost:7261/Leads/add', 
    'https://fidelidadeapi.quickflowai.com/Leads/add',
    {
    method: 'POST',
    body: JSON.stringify(data),  
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const result = await response.json();
 

// Se a variável for nula ou indefinida, gere um novo GUID
if (!sessionValueGuid) {
  sessionValueGuid = getUUID(); // Gera um novo GUID
  sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Salva no sessionStorage
}

 
   if(response.status == 200){
    setIsSubmitting(true);
    console.log("start");

    
    if (result.value == "Duplicate lead"){
      setFromstep(5)
    }else{
      const today = new Date();
      const day = today.getDay();
      if (day >= 1 && day <= 4){
        setFromstep(3)
        const img = document.createElement("img");
        img.src = `https://emas.uinterbox.com/tracking/cnv?org=5634&evt=11103&cid=${sessionValueGuid }`;
        img.height = "1";
        img.width = "1";
        img.style.display = "none"; // Opcional: esconde a imagem
        document.body.appendChild(img);

      }else{
        setFromstep(4)
        const img = document.createElement("img");
        img.src = `https://emas.uinterbox.com/tracking/cnv?org=5634&evt=11103&cid=${sessionValueGuid }`;
        img.height = "1";
        img.width = "1";
        img.style.display = "none"; // Opcional: esconde a imagem
        document.body.appendChild(img);
        
      }
    }

     }
}





function getSaudacao() {
  const now = new Date();
  const hour = now.getHours();

  if (hour >= 6 && hour < 13) {
    return "Bom dia";
  } else if (hour >= 13 && hour < 20) {
    return "Boa tarde";
  } else {
    return "Boa noite";
  }}


  
  const sendDataToGoogleSheet = async ({
    fromwhere ="",
    name = "",
    email = "",
    phone = "",
    vat = "",
    postalCode = "",
    observations = "N/A",
    petname= "N/A",
    petage = "N/A",
    publisher = "N/A"
  } = {}) => {
    // URL do Google Apps Script com CORS Anywhere como proxy
    const scriptUrl = 'https://script.google.com/macros/s/AKfycbzn-Mbyx3RixBni7507Zpsibl1dqJHC21Dlu9tPDg_TS0lk8p-q11gyvSPsqx4rIzfH/exec';
    const proxyUrl = 'https://corsproxy.io/?';

    

  
  
    let sessionValue = sessionStorage.getItem('user_session');
    let sessionValueGuid = sessionStorage.getItem('user_sessionGuid');
    
    // Verifica e define `user_session`
    if (!sessionValue) {
      sessionValue = `session_${new Date().getTime()}`;  
      sessionStorage.setItem('user_session', sessionValue);  
    }
    
    // Verifica e define `user_sessionGuid` (GUID)
    if (!sessionValueGuid) {
      sessionValueGuid = getUUID(); // Gera um GUID único
      sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Armazena o GUID no sessionStorage
    }
  
 
    const getGeoLocation = async () => {
      try {
        const response = await fetch('https://ipinfo.io/json');
    
        if (!response.ok) {
          throw new Error('Failed to fetch IP information');
        }
    
        const result = await response.json();
    
        // Use the correct field names returned by ipinfo.io
        if (result && result.country && result.region && result.city) {
          return `${result.country}, ${result.region}, ${result.city}`;
        } else {
          return 'Localização não encontrada';
        }
      } catch (error) {
        console.error('Erro ao obter geolocalização:', error);
        return 'Localização não encontrada';
      }
    };
    
  
    
    const getIPAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip; // Retorna o IP público
      } catch (error) {
        console.error('Erro ao obter o IP:', error);
        return 'IP Não Disponível';  
      }
    };

    const geoIp = await getGeoLocation();
    const userIP = await getIPAddress();

    // Definir valores padrão internos
    const data = {
      Campaign: 'Multicare',
      Session: sessionValue, 
      Date: new Date().toISOString(),  
      IP: userIP || 'N/A', 
      Device: navigator.userAgent.includes('Mobile') ? 'Mobile' : 'Desktop',  
      Geo_IP: geoIp,  
      Name: name, 
      Email: email,  
      Phone: phone,  
      Vat: vat,  
      PostalCode: postalCode,  
      Observations: observations,
      PetName:petname, 
      PetAge:petage, 
      Publisher: publisher ,
      UUID: sessionValueGuid 
    };
  
    try {
     
      const response = await fetch(proxyUrl + scriptUrl, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.text();
      console.log(`Sucess ${result}`);
    } catch (error) {
      console.error(`Error ${error}`);
    }
  };
  

  
  const saudacao = getSaudacao();
  return (
    

    
    <>

<div className="w-full bg-[#F0F1F3] p-5 flex items-center justify-between fixed top-0 left-0 z-50">
  {/* Logo aligned left on mobile and centered on desktop */}
  <img
    src={logo}
    alt="Logo"
    className="max-w-[200px] lg:max-w-[300px] md:max-w-[250px] lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2"
  />

  {/* Text always aligned to the right */}
  <span className="text-right mr-2 ml-auto">
    <span className="text-sm">SEGURO DE SAÚDE</span> <br />
    <span className="text-xl font-bold">DESDE 14,91 € *</span>
  </span>
</div>





 <div className="w-full flex flex-col lg:flex-row ">
 
 <div className="w-full flex flex-col bg-[rgb(239,171,0)] lg:bg-[url('../src/assets/hero.a1741709.jpg')] bg-no-repeat bg-cover bg-center pt-4 lg:pt-12 text-white mt-[60px]">

  {/* Primeiro bloco: Alinhado no topo à esquerda */}
  <div className="flex justify-start items-start">
  <p className="hidden lg:block font-bebasneuepro text-5xl lg:text-6xl font-bold mt-5 ml-5" style={{ color: "#EFAB00" }}>
  SEGURO DE SAÚDE<br />
  <span style={{ color: "rgb(255, 255, 255)", fontSize: "3rem" }}>MULTICARE</span>
</p>



  </div>
  
  {/* Segundo bloco: Alinhado ao centro */}
  <div className="flex flex-col items-center justify-center gap-4 max-w-[400px] lg:max-w-[400px] mx-auto mt-10">
   
        {/* Div de mais-valia com ícone e texto */}
        <div className="benefit-item border border-white lg:border-none p-3 rounded-md">
  <FaCheckCircle size={24} className="benefit-icon" />
  <h5 className="benefit-text">SEGURO sem idade limite de permanência</h5>
</div>
<div className="benefit-item border border-white lg:border-none p-3 rounded-md">
  <FaUserMd size={24} className="benefit-icon" />
  <h5 className="benefit-text">CONSULTAS MÉDICAS 24/7 SEM CUSTOS ADICIONAIS</h5>
</div>
<div className="benefit-item border border-white lg:border-none p-3 rounded-md">
  <FaHeartbeat size={24} className="benefit-icon" />
  <h5 className="benefit-text">PROGRAMA DE RASTREIOS PERIÓDICOS GRATUITOS</h5>
</div>
<div className="benefit-item border border-white lg:border-none p-3 rounded-md mb-5">
  <FaPercentage size={24} className="benefit-icon" />
  <h5 className="benefit-text">DESCONTOS ATÉ 15% PARA FAMÍLIAS</h5>
</div>

      </div>
    </div>


    <div className='w-full bg-[#EFAB00] pb-5 lg:px-16 lg:pb-16' id="askproposal">


    <div className="w-full h-[80px] bg-[#F0F1F3] hidden md:block">
    </div>
    <p
  className={`text-white font-bebasneuepro text-base lg:text-3xl font-semibold flex justify-center md:justify-between items-center w-full mt-[20px] pt-[10px] pb-[10px] ${
    formstep == 3 ? 'hidden' : 'block'
  }`}
>
  {/* Texto Principal com Ícone */}
  <h2 className="w-full text-center md:text-left text-2xl md:text-2xl font-bold flex justify-center md:justify-start items-center text-black">
    {/* Ícone de Coração a Preto */}
    <FaHeartbeat className="mr-2 text-white" />
    SOLUÇÕES QUE SE ADAPTAM À SUA SAÚDE
  </h2>
</p>






      <div className={`bg-white rounded-xl mx-4 pt-2 px-4 lg:px-12 mb-3 lg:h-[500px] ${formstep > 2 ? 'hidden' : 'block'}`}>

  <div className={` ${formstep == 1 ? 'block' :'hidden'}`}>
  <p className='font-bebasneuepro font-semibold text-center py-2 uppercase lg:mb-2' style={{ fontSize: '24px' }}>

    
    
  {saudacao}, preencha os dados para pedir a sua simulação. </p>

  <div className='px-5 relative mb-3'>
 <input type='text' ref={nomeinp} name="name" value={formdata.name} onChange={validateform}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='O seu nome é?'   maxLength={100} /> 
 <span className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <FaUser className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
  

 </div>

 <div className='px-5 relative mb-3'>
 <input type='text' ref={emailinp} name="email" value={formdata.email} onChange={validateform}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='Email'   maxLength={100} /> 
 <span id="emaillbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <FaEnvelope className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
  
 </div>
 <div className="pl-5 py-3.5">


 <p className='text-xs lg:text-md text-[#212121]'>
  <strong
    onClick={() => setShowMoreText(!showMoreText)} // Toggle on click
    style={{ cursor: 'pointer' }} // Pointer style
  >
    Ver termos e Condições
  </strong>
  <br />
  {/* Conditionally render additional text */}
  {showMoreText && (
    <div>
      <span>
        Os dados pessoais por si acima facultados serão tratados <strong>para posterior contacto telefónico com a finalidade de realizar a simulação</strong> e para fazer o acompanhamento comercial da mesma, com fundamento no desenvolvimento de diligências pré-contratuais a pedido do titular e no interesse legítimo do desenvolvimento da atividade comercial da Fidelidade.
      </span>
      <p className='text-themered text-xs leading-1 py-4'>
        <a
          href='https://www.fidelidade.pt/PT/protecao-dados/Documents/Politica_de_Privacidade_e_Protecao_de_Dados_Pessoais%E2%80%93FIDELIDADE.pdf'
          className='underline text-themered'
          target='_blank'
          rel='noopener'
          
        >
          Consulte aqui, informação mais detalhada sobre proteção e tratamento dos seus dados pela Fidelidade.
        </a>
      </p>
      <br />
    </div>
  )}
</p>


  </div>
   </div>
  
 <div className={` ${formstep === 2 ? 'block' :'hidden'}`}>
 <p className='font-bebasneuepro font-semibold text-center py-2 uppercase lg:mb-2' style={{ fontSize: '24px' }} id="Txt2Step">Preencha o formulário</p>

 
 
{DivShowNameEmail === 1 && (

  <>
    <div className='px-5 relative mb-3'>
      <input 
        type='text' 
        ref={nomeinp} 
        name="name" 
        value={formdata.name} 
        onChange={validateform}  
        className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal`} 
        placeholder='O seu nome é?' 
        maxLength={100} 
      /> 
      <span className='absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered'></span>
      <FaUser className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
    </div>

    <div className='px-5 relative mb-3'>
      <input 
        type='text' 
        ref={emailinp} 
        name="email" 
        value={formdata.email} 
        onChange={validateform}  
        className={`block w-full py-4 pl-12 pr-8 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal`} 
        placeholder='Email' 
        maxLength={100} 
      /> 
      <span id="emaillbl" className='absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered'></span>
      <FaEnvelope className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
    </div>
  </>
)}

 <div className='px-5 relative mb-3'>
 <input type='number' inputmode="numeric"  ref={nifinp} name="nif" value={formdata.nif} onChange={validateform}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='NIF'  maxLength={9} /> 
 <span id="niflbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <FaIdCard  className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
  
 </div>

<div className='flex flex-col lg:flex-row mb-1'>
<div className='w-full px-5 relative mb-3'>
 <input type='text' inputmode="numeric"  ref={zipinp} name="zip" value={formdata.zip} onChange={validatezip}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='Código Postal'   maxLength={8} /> 
 <span id="ziplbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <FaMapMarkerAlt   className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
  
 </div>

 <div className='w-full px-5 relative mb-3'>
 <input type='number' inputmode="numeric"  name="phone" ref={mobileinp} value={formdata.phone} onChange={validateform}  className={`block w-full py-4 pl-12 pr-8   rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal `} placeholder='Nº Telemóvel'   maxLength={9} /> 
 <span id="mobilelbl" className='absolute top-[1px] left-16 pl-1   text-[0.7em]  transition-all duration-10 text-themered' ></span>
 <FaPhoneAlt className='absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]' />
  
 </div>

  </div>

 </div>


 


 <div className="relative w-full flex justify-center items-center">
  {/* Back Button (Left-Aligned, Absolute Position) */}
  <button
    onClick={() => setFromstep(1)}
    className={`absolute left-0 bg-gray-300 hover:bg-gray-400 rounded-full w-[40px] h-[40px] flex justify-center items-center ${
      formstep === 1 ? 'invisible' : 'visible'
    }`}
    style={{ marginLeft: '20px' }}  // Optional: Add margin if needed
  >
    <img src={arrowcta} alt="Back Arrow" className="w-[10px] rotate-180" />
  </button>

 
 
<button
  onClick={() => {
    if (formstep === 1) {
      submitform(1);
    } else if (formstep === 2) {
      submitform(2);
    }
  }}
  className={`flex items-center justify-center w-[230px] ${
    formstep === 1 ? 'bg-[#28a745] hover:bg-[#86ad5e]' : 'bg-[#FF5722] hover:bg-[#FF7043]'
  } text-center rounded-md p-2.5 text-white font-bebasneuepro font-semibold text-lg lg:text-2xl focus:outline-none focus:ring-4 ${
    formstep === 1 ? 'focus:ring-[#86ad5e]' : 'focus:ring-[#FF7043]'
  } shadow-lg transition-all duration-200`}
  
  
  role="button"
  aria-label={formstep === 1 ? 'Ir para o próximo passo' : 'Solicitar simulação'}
>
  {/* Conditionally render different icons for each step */}
  {formstep === 1 ? (
    <>
      <FaArrowRight className="mr-2" /> {/* Icon for Step 1 */}
      PRÓXIMO PASSO
    </>
  ) : (
    <>
      <FaCheck className="mr-2" /> {/* Icon for Step 2 (Pedir Simulação) */}
      PEDIR SIMULAÇÃO
    </>
  )}
</button>

</div>


 

 
  {formstep < 3 && (
    <div className="flex justify-center pt-5">

<div className="w-[300px] lg:w-[500px] h-8 bg-gray-300 rounded-full mt-2 mb-[30px]">

    <div
      className="h-full bg-[#28a745] rounded-full transition-all duration-300"
      style={{ width: formstep === 1 ? '30%' : formstep === 2 ? '70%' : '0%' }}
    ></div>
  </div>
</div>

  )}

 


</div>

 

<div className={`max-w-[90%] lg:max-w-[95%] ${formstep == 3? 'block' : 'hidden'} text-white text-left font-bebasneuepro text-2xl lg:text-3xl p-[30px]`}><p className={`text-white text-left font-bebasneuepro text-2xl lg:text-2xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>
Olá  {formdata["name"]}!</p>
Obrigado pelo seu pedido de simulação.<br />
Em breve, a nossa equipa entrará em contacto consigo para lhe apresentar a nossa melhor proposta.<br />
Enquanto isso, pode explorar mais sobre os nossos produtos e serviços no nosso site:  <br></br>
<a href='https://www.fixo.pt/fixo/?utm_source=referral&utm_medium=afiliacao&utm_campaign=fid_pontual_fixo_referral_afiliacao_lp_pets_typ&utm_content=produto' target='_blank' className="text-black font-bold">www.fixo.pt</a><br />
Obrigado<br /> <br></br>
<strong>Fidelidade</strong>
</div>
<div className={`max-w-[90%] lg:max-w-[95%] ${formstep == 4 ? 'block' : 'hidden'} text-white text-left font-bebasneuepro text-2xl lg:text-3xl p-[30px]`}>
<p className={`text-white text-left  font-bebasneuepro text-2xl lg:text-2xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>

  Olá  {formdata["name"]}!</p>
Obrigado pelo seu pedido de simulação.<br />
Em breve, a nossa equipa entrará em contacto consigo para lhe apresentar a nossa melhor proposta.<br />
Enquanto isso, pode explorar mais sobre os nossos produtos e serviços no nosso site:  <br></br>
<a href='https://www.fixo.pt/fixo/?utm_source=referral&utm_medium=afiliacao&utm_campaign=fid_pontual_fixo_referral_afiliacao_lp_pets_typ&utm_content=produto' target='_blank' className="text-black font-bold">www.fixo.pt</a><br />
Obrigado<br /> <br></br>
<strong>Fidelidade</strong>
 
</div>

<div className={`max-w-[90%] lg:max-w-[95%] ${formstep == 5 ? 'block' : 'hidden'} text-white text-left font-bebasneuepro text-2xl lg:text-3xl p-[30px]`}>
<p className={`text-white text-left  font-bebasneuepro text-2xl lg:text-2xl mb-3 font-semibold mt-10 lg:mt-5 lg:mb-5 `}>
Obrigado  {formdata["name"]}!</p>
Verificamos que já tinha pedido um contacto anteriormente.<br />
A nossa equipa entrará em contacto consigo o mais breve possível para ajudar a esclarecer.<br />
Enquanto isso, pode explorar mais sobre os nossos produtos e serviços no nosso site:  <br></br>
<a href='https://www.fixo.pt/fixo/?utm_source=referral&utm_medium=afiliacao&utm_campaign=fid_pontual_fixo_referral_afiliacao_lp_pets_typ&utm_content=produto' target='_blank' className="text-black font-bold">www.fixo.pt</a><br />
Obrigado<br /> <br></br>
<strong>Fidelidade</strong>
</div>

<div className={`App ${formstep > 2 ? 'block' : 'hidden'} w-[90%] mx-auto bg-white rounded-lg text-center`} style={{ padding: '40px', marginBottom: '20px' }}>
 





  <p className="text-black font-bold text-xl">
    Dentro de {countdown} segundos será redirecionado para{' '}
    <a href={targetUrl} className="text-blue-400 underline">www.fixo.pt</a>
  </p>
</div>

      </div>


      
    </div>

    <div style={{ padding: '20px 0' }}></div>
    <div className="w-full text-center pt-[10px] pb-[10px] lg:w-[500px] w-[90%] mx-auto">
  {/* Primeiro parágrafo com letras maiores e bold */}
  <h1  className="text-[24px] font-bold mb-4 w-full">
    Multicare: vantagens diferenciadoras
  </h1>

  {/* Segundo parágrafo com estilo padrão */}
  <p className="text-[20px] w-full">
    Com a Multicare pode beneficiar de vantagens exclusivas e diferenciadoras, dependendo da solução contratada.
  </p>
</div>

<SecondVisitModal />
<BenefitsGrid/>
<div style={{ padding: '20px 0' }}></div>
<NotificationPopUp />
    <Footer />
    </>
   
  );
}

export default App;