import React from 'react';
import logofooter from '../src/assets/logo_multicare.svg';
import { FaShieldAlt, FaPercent, FaStethoscope, FaPhoneAlt } from 'react-icons/fa'; // Ícones para a primeira seção
import { TiArrowUpOutline } from "react-icons/ti";
function Footer() {
  return (
    <footer>
      {/* Primeira Seção com um div ocupando 100% da largura */}

      <div style={{ textAlign: 'center' }}>

      <button
  type="submit"
  className="conversion-btn flex items-center mx-auto"
  onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
>
  <TiArrowUpOutline className="mr-2" />
  Quer saber mais? 
</button>



                                        </div>
                                        <div style={{ marginBottom: '40px' }}></div>



      <section className="checks">

        <div className="checks-wrapper">
          <div className="tw-container">
          <div >
            <h3 className="pt-[15px] pb-[15px] text-[20px] font-bold">
  RAZÕES Para confiar nos Seguros de Saúde da Multicare</h3>
</div>

            <div className="tw-grid">
              <div className="benefit">
                <FaShieldAlt className="icon" />
                <h4><b>Marca líder</b> em Seguros de Saúde</h4>
              </div>
              <div className="benefit">
                <FaPercent className="icon" />
                <h4><b>Até 15% desconto</b> sobre o prémio comercial para proteger toda a família</h4>
              </div>
              <div className="benefit">
                <FaStethoscope className="icon" />
                <h4><b>Programa de rastreios gratuito</b> de 2 em 2 anos (nas condições em vigor em cada momento)</h4>
              </div>
              <div className="benefit">
                <FaPhoneAlt className="icon" />
                <h4><b>Acesso a médicos</b> por telefone sem custos adicionais</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Segunda Seção com logo e texto */}
      <section className="footer-info">
        <div className="footer-container">
          <div className="footer-logo">
            <img src={logofooter} alt="Logo Fidelidade" />
          </div>

          <div style={{ textAlign: 'center' }} className="block lg:hidden">
  <button
    type="submit"
     className="conversion-btn flex items-center mx-auto"
    onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
  >
    <TiArrowUpOutline className="mr-2" />
    Pedir a sua simulação!
  
  </button>
</div>

          <div className="footer-text">
            <p>
              * Prémio total para fracionamento mensal, aplicável durante a 1ª anuidade, para uma pessoa de 30 anos que subscreva o seguro Multicare 1. 
            <br></br>Campanha válida para subscrição de um seguro de saúde Multicare até 31 de dezembro 2024.
              <br></br>Esta informação não dispensa a consulta da informação pré-contratual e contratual legalmente exigida.
 

              <br></br>
              Fidelidade - Companhia de Seguros, S.A.- NIPC e Matrícula 500 918 880, na CRC Lisboa - Sede: Largo do Calhariz, 30 1249-001 Lisboa – Portugal - Capital Social EUR 509.263.524<br />
              Apoio ao Cliente: Dias úteis das 9h às 20h. Assistência: todos os dias 24h/dia - T. 217 948 880 Chamada para a rede fixa nacional. E. <a href="mailto:apoiocliente@multicare.pt">apoiocliente@multicare.pt</a> . <a href="https://www.multicare.pt" target="_blank" rel="noopener noreferrer">www.multicare.pt</a>
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
