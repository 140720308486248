import React, { useState } from 'react';
import img1 from '../src/assets/1.89e4263f.svg';
import img2 from '../src/assets/2.8a65b6cc.svg';
import img3 from '../src/assets/3.f7f36d66.svg';
import img4 from '../src/assets/4.d22b1415.svg';
import img5 from '../src/assets/5.4d1cbe8f.svg';
import img6 from '../src/assets/6.85483f49.svg';
import img7 from '../src/assets/7.e0a5f402.svg';
 
// Definição dos benefícios
const benefits = [
  { image: img1, title: "ACESSO AO PROGRAMA MULTICARE VITALITY", description: "Programa que recompensa por hábitos de vida saudável com descontos em parceiros (Celeiro, Decathlon, entre outros), vantagens na compra de um smartwatch (Apple ou Garmin) e bónus no prémio do seu seguro." },
  { image: img2, title: "MÉDICO SEMPRE DISPONÍVEL, POR TELEFONE, SEM CUSTOS", description: "Acesso a consultas 24h/7 dias a partir de qualquer lugar de Medicina Geral e Familiar, Médico Assistente Online, Psicologia, Consultas de Especialidade, Serviços de Promoção de Vida Saudável, entre outros." },
  { image: img3, title: "PROGRAMA DE RASTREIOS PERIÓDICOS GRATUITOS", description: "A Multicare aposta na prevenção e em programa de rastreios, para avaliar a sua saúde. Sem custos adicionais e de acordo com as condições em cada momento vigor." },
  { image: img4, title: "SAÚDE MENTAL", description: "Uma cobertura diferenciadora que abrange a prevenção e o tratamento da saúde mental. Garante a comparticipação de Internamento Psiquiátrico e Consultas de Psiquiatria, Psicologia e Sessões de Psicoterapia." },
  { image: img5, title: "CAPITAIS ELEVADOS E COBERTURAS ABRANGENTES", description: "Soluções com proteção crescente, para que possa escolher a que melhor se adapta às suas necessidades e da sua família. Capitais de Internamento Hospitalar até 100.000€ e de ambulatório até 5.000€ e um amplo leque de coberturas opcionais: Estomatologia, Próteses e Ortóteses e Doenças Graves." },
  { image: img6, title: "DESCONTOS ATÉ 15% PARA FAMÍLIAS", description: "Ao incluir os diferentes membros do seu agregado familiar no seguro, pode usufruir de um desconto de até 15% sobre o prémio comercial." },
  { image: img7, title: "UMA APP PARA GERIR O SEU SEGURO", description: "Na app MyFidelidade pode fazer o pedido de reembolso de despesas de saúde através de fotografia das faturas/recibos, sem necessidade de enviar os originais das despesas por correio. Pode ainda fazer a gestão online do seu seguro, gerar um cartão Multicare digital e muito mais." }
];

// Componente Accordion Customizado
const BenefitAccordion = ({ image, title, description }) => {
  const [isOpen, setIsOpen] = useState(false); // O estado inicial é `false`, indicando que está fechado

  return (
    <div className="accordion-item">
      {/* Cabeçalho do Acordeão */}
      <div
        className={`accordion-header ${isOpen ? 'active' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={image} alt={title} className="accordion-icon" />
        <h3 className="accordion-title">{title}</h3>
        <span className={`accordion-toggle ${isOpen ? 'open' : ''}`}>{isOpen ? '-' : '+'}</span>
      </div>
      
      {/* Conteúdo do Acordeão (visível apenas se `isOpen` for true) */}
      {isOpen && (
        <div className="accordion-content">
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

// Componente Grid que renderiza todos os benefícios em duas colunas
const BenefitsGrid = () => (
  <div className="benefits-grid">
    {benefits.map((benefit, index) => (
      <div className="grid-item" key={index}>
        <BenefitAccordion image={benefit.image} title={benefit.title} description={benefit.description} />
      </div>
    ))}
  </div>
);


export default BenefitsGrid;
