import React, { useEffect, useState } from 'react';

const SecondVisitNotification = () => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    // Recupera o número de visitas do localStorage
    let visitCount = parseInt(localStorage.getItem('visitCount')) || 0;

    // Incrementa o contador de visitas
    visitCount += 1;

    // Atualiza o contador de visitas no localStorage
    localStorage.setItem('visitCount', visitCount);

    // Verifica se a simulação foi completada
    const isSimulationCompleted = localStorage.getItem('isSimulationCompleted') === 'true';
   
    // Verifica se é a 2ª, 4ª ou 6ª visita e a simulação **não foi completada**
    if (!isSimulationCompleted && (visitCount === 2 || visitCount === 4 || visitCount === 6)) {
      setShowNotification(true);
    }
  }, []);

  // Função para fechar a notificação e rolar para o formulário
  const handleButtonClick = () => {
    const element = document.getElementById('askproposal');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setShowNotification(false); // Fechar a notificação
  };

  return (
    <>
      {showNotification && (
        <div style={styles.notificationContainer}>
          <p style={styles.notificationText}>
            Reparámos que visitou o nosso site novamente, mas ainda não preencheu o formulário para a simulação.<br></br><br></br>
            Com a Multicare pode beneficiar de vantagens exclusivas e diferenciadoras, dependendo da solução contratada.
          </p>
          <button style={styles.button} onClick={handleButtonClick}>
            Pedir Simulação
          </button>
        </div>
      )}
    </>
  );
};

// Definição dos estilos do componente
const styles = {
  notificationContainer: {
    position: 'fixed',
    top: '20px',
    right: '20px',
    backgroundColor: '#EFAB00',
    padding: '15px 20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1050,
    textAlign: 'center',
    maxWidth: '300px',
  },
  notificationText: {
    margin: 0,
    color: '#000', // Cor do texto preta
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: 'bold', // Texto em negrito
  },
  button: {
    marginTop: '10px',
    backgroundColor: '#fff',
    color: '#EFAB00',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
};

export default SecondVisitNotification;
