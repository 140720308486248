import React, { useEffect, useRef } from 'react';
import { IoIosInformationCircle } from 'react-icons/io'; // Import the icon
import { TiArrowUpOutline } from "react-icons/ti";


const NotificationPopUp = () => {
  const notificationRef = useRef(null); // Ref for the notification element
  const closeBtnRef = useRef(null);     // Ref for the close button
  const conversionBtnRef = useRef(null);// Ref for the conversion button

  useEffect(() => {
    // Function to show the notification on scroll
    const handleScroll = () => {
      if (window.scrollY > 300 && notificationRef.current) {
        notificationRef.current.classList.add("active-unique");
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Function to handle closing the notification
    const handleClose = () => {
      if (notificationRef.current) {
        notificationRef.current.classList.add("hidden");
      }
    };

    // Function to handle conversion button click
    const handleConversionClick = (event) => {
      event.preventDefault(); // Prevent default button behavior
      if (notificationRef.current) {
        notificationRef.current.classList.add("hidden");
      }
      document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' });
    };

    // Attach event listeners
    if (closeBtnRef.current) {
      closeBtnRef.current.addEventListener('click', handleClose);
    }

    if (conversionBtnRef.current) {
      conversionBtnRef.current.addEventListener('click', handleConversionClick);
    }

    // Cleanup events and references when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (closeBtnRef.current) {
        closeBtnRef.current.removeEventListener('click', handleClose);
      }
      if (conversionBtnRef.current) {
        conversionBtnRef.current.removeEventListener('click', handleConversionClick);
      }
    };
  }, []);

  // JSX structure for the notification popup
  return (
    <div id="slideInNotificationUnique" ref={notificationRef} className="slide-in-unique">
      <span className="close-btn-unique" ref={closeBtnRef}>&times;</span>
      <h2 className="flex items-center text-lg lg:text-2xl font-bold">
  <IoIosInformationCircle className="mr-2 text-black" />
  Sabia que:
</h2>

      <p>
       Todos os nossos planos proporcionam o <b>acesso imediato, exclusivo</b> e sem qualquer custo, a <b>cuidados primários</b> a partir de sua casa, <b>24 horas por dia, 7 dias</b>.
      </p>
      <div>
        {/* You can add more content or images here as needed */}
      </div>
      <button
  id="conversionBtnUnique"
  ref={conversionBtnRef}
  className="conversion-btnbasic flex items-center justify-center"
  style={{ color: "#ffffff", marginTop: "10px" }}
>
  <TiArrowUpOutline className="mr-2" />
  Fazer já simulação.
</button>

    </div>
  );
};

export default NotificationPopUp;
